import React from "react"
import { createRoot } from 'react-dom/client'
import "./style.css"
import App from "./app"
import * as serviceWorker from "./serviceWorker"

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App/>)

serviceWorker.register()
